<template>
    <el-card
        v-loading="loading"
        class="box-card worker-card"
        :class="{
            worker_status_unknown: (this.worker.worker_status=='unknown'),
            worker_status_rendering: (this.worker.worker_status=='rendering'),
            worker_status_idle: (this.worker.worker_status=='idle'),
            worker_status_offline: (this.worker.worker_status=='offline'),
            worker_status_stalled: (this.worker.worker_status=='stalled'),
            worker_status_startingjob: (this.worker.worker_status=='startingjob'),
            worker_status_disabled: (this.worker.worker_status=='disabled'),
            offline_warning: OfflineWarning
        }"
    >
      <template #header>
        <div class="card-header">
          <el-text v-if="this.worker.description" class="mx-1">{{ this.worker.name }} - {{ this.worker.description }}</el-text>
          <el-text v-else class="mx-1">{{ this.worker.name }}</el-text>
        </div>
      </template>

      <!-- <p class="text item">IP: {{ this.worker.Info.IP }}</p> -->
      <p v-if="this.worker.pcoip_assigned" class="text item">
        PCOIP - {{ this.worker.pcoip_assigned }}
      </p>
      <p class="text item">logged in: {{ this.worker.Info.User }}</p>

      <p class="text item" v-if="this.worker.last_render_datetime_mins">
        {{this.worker.worker_status}} for {{ minToDays(this.worker.last_render_datetime_mins) }}
      </p>

      <template #footer>

        <!-- <el-menu
          ellipsis
          :popper-offset="16"
          style="max-width: 600px"
        >
          <el-sub-menu index="1">
            <template #title>Power</template>
            <el-menu-item index="1-1" @click="PowerOn()">On</el-menu-item>
            <el-menu-item index="1-2" @click="PowerRestart()">Restart</el-menu-item>
            <el-menu-item index="1-3" @click="PowerOff()">Off</el-menu-item>
          </el-sub-menu>
        </el-menu> -->

          <div class="mb-4">
        <el-button
            type="success"
            round
            plain
            bg
            @click="PowerOn()"
        >
          {{ power_button_on_state }}
        </el-button>

        <el-button
            type="danger"
            round
            plain
            bg
            @click="PowerOff()"
        >
            {{ power_button_off_state }}
        </el-button>

        <el-button
            type="warning"
            round
            plain
            bg
            @click="PowerRestart()"
        >
        {{ power_button_restart_state }}
        </el-button>
      </div>  
    </template>
    </el-card>
</template>

<script>
import axios from "axios";

export default {
  name: 'WorkerCard',
  data() {
        return {
            loading: false,
            power_button_on_state: 'On',
            power_button_off_state: 'Off',
            power_button_restart_state: 'Restart',
            offline_warning_threshold: (24*60)*3,
        }
    },
    props: {
        worker: Object
    },
    computed: {
        OfflineWarning() {
            if (this.worker.stat_date_mins > this.offline_warning_threshold) {
                return true
            }
            else {
                return false
            }
        }
    },
    methods: {
        minToDays(min) {
            let days = Math.floor((min) / 1440);
            let remainingTime = parseInt((min) - Math.floor((days *1440)));
            let hours = Math.floor((remainingTime / 60));
            let remainingMin = Math.floor(remainingTime - (hours*60));

            let return_string = '';
            
            if (days > 0) {
                return_string = return_string + `${days}d`
            }
            if (hours > 0) {
                return_string = return_string + ` ${hours}h`
            }
            if (remainingMin > 0) {
                return_string = return_string + ` ${remainingMin}m`
            }

            return return_string
        },
        async PowerOn() {
            // set to confirm if it was 'On'
            if (this.power_button_on_state == 'On') {
                this.power_button_on_state = 'Confirm'
            }
            // Run command it if it said 'Confirm'
            else {
                this.loading = true
                this.power_button_on_state = 'On'
                let worker_name = this.worker.Info.Name
                const { data } = await axios.get(window.location.origin + '/api/workers/power/on/' + worker_name);
                console.log('power on request', data)
                this.loading = false

                // // Set status so the UI changes, but refresh will be an accurate reading
                // if (this.worker.worker_status=='offline') {
                //     this.worker.worker_status = 'idle'
                // } 
            }

        },
        async PowerRestart() {
            // set to confirm if it was 'Restart'
            if (this.power_button_restart_state == 'Restart') {
                this.power_button_restart_state = 'Confirm'
            }
            // Run command it if it said 'Confirm'
            else {
                this.loading = true
                this.power_button_restart_state = 'Restart'
                let worker_name = this.worker.Info.Name
                const { data } = await axios.get(window.location.origin + '/api/workers/power/restart/' + worker_name);
                this.loading = false
                console.log('power restart request', data)
            }
        },
        async PowerOff(){
            // set to confirm if it was 'Off'
            if (this.power_button_off_state == 'Off') {
                this.power_button_off_state = 'Confirm'
            }
            // Run command it if it said 'Confirm'
            else {
                this.loading = true
                this.power_button_off_state = 'Off'
                let worker_name = this.worker.Info.Name
                const { data } = await axios.get(window.location.origin + '/api/workers/power/off/' + worker_name);
                this.loading = false
                console.log('power off request', data)

                // // Set status so the UI changes, but refresh will be an accurate reading
                // if (this.worker.worker_status != 'offline') {
                //     this.worker.worker_status = 'offline'
                // }
            }
        },
    }
}
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
}

.worker-card {
  width: 13rem;
  height: 12rem;
}

.worker-card *{
  padding: 0.25rem;
}

.worker-card .item {
  margin: 0.0rem;
}
.worker_status_disabled {
  background-color: #716d26;
  border-color: #e1d953;
}

.worker_status_idle {
  background-color: #2d2d2f;
}

.worker_status_offline {
  opacity: 0.5;
}
.worker_status_rendering {
  background-color: #4e8e2f;
}

.worker_status_stalled {
  background-color: #854040;
}



.el-menu--horizontal > .el-menu-item:nth-child(1) {
  margin-right: auto;
}


.offline_warning {
    animation: blinker 1s ease-in-out infinite;
}    

@keyframes blinker {
  50% {
    opacity: 0.75;
  }
}


</style>