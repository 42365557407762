<template>

<div>

  <el-row >
    <el-col :span="8"><div class="top-bar grid-content">last update: {{ last_update }}</div></el-col>
    <el-col :span="8"><div class="top-bar grid-content">FRONT: {{temp_front}}</div></el-col>
    <el-col :span="8"><div class="top-bar grid-content">REAR: {{temp_rear}}</div></el-col>
  </el-row>

  <FarmJobs :jobs="all_jobs"></FarmJobs>

  <el-space wrap>
    <WorkerCard
      v-for="worker in workersSorted"
      :key="worker.Info._id"
      :worker="worker"
    >
    </WorkerCard> 
  </el-space>

<!--   
  <li v-for="item in pcoip_users" :key="item">
    {{ item }}
  </li>
   -->


  </div>

</template>

<script>

import axios from "axios";
import WorkerCard from './components/WorkerCard.vue'
import FarmJobs from './components/FarmJobs.vue'

export default {
  name: 'App',
  data() {
    return {
        all_jobs: {},
        workers: {},
        last_update: '',
        temp_front: false,
        temp_rear: false
    }
  },
  components: {
    WorkerCard,
    FarmJobs
  },
  computed: {   
    workersSorted() {
      return this.workers
    }
  },
  methods: {
    // WorkerStatusColor(status_index) {
    //     // rendering
    //     if (status_index == 1 ){
    //       return 'active'
    //     }
    //     // Offline
    //     if (status_index == 3 ){
    //       return 'offline'
    //     }
    //     // Stalled
    //     if (status_index == 4 ){
    //       return 'stalled'
    //     }
    // },
    // async getPCOIPUsers() {
    //     const { data } = await axios.get(window.location.origin + '/api/pcoip_users/');
    //     this.pcoip_users = data;
    //     // console.log(data)
    // },
    async getJobsActive() {
        const { data } = await axios.get(window.location.origin + '/api/jobs/active/');
        // console.log('jobs', data)
        this.all_jobs = data;
    },
    async getWorkers() {
        this.last_update = new Date().toLocaleString()
        const { data } = await axios.get(window.location.origin + '/api/workers/');       
        // console.log('workers', data)
        this.workers = data;
    },
    async getClimate() {
        this.last_update = new Date().toLocaleString()
        const { data } = await axios.get(window.location.origin + '/api/climate/');
        this.temp_rear = data[0].temperature01;
        this.temp_front = data[0].temperature02;
    },
    
  },
  beforeMount() {
    // this.getPCOIPUsers();
    this.getJobsActive()
    this.getWorkers()
    this.getClimate()
  },
  mounted() {
    setTimeout(function(){
      window.location.reload(1);
    }, 60000);
  }
}

</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
}

.el-menu--horizontal > .el-menu-item:nth-child(1) {
  margin-right: auto;
}

.el-table .warning-row {
  --el-table-tr-bg-color: blue;
}
.el-table .success-row {
  --el-table-tr-bg-color: red;
}
.top-bar {
  font-size: x-large;
    color: #a77730

}
</style>
