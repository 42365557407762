<template>
  
  <el-table
    :data="jobs"
    :default-sort="{ prop: 'Date', order: 'descending' }"
    style="width: 100%"
  >
    <el-table-column label="Job Name" min-width="200" sortable>
      <template #default="scope">
        <div
          :style="{backgroundImage: createBackgroundString(scope.row)}"
        >
        <el-text class="mx-1" size="large">
          {{ scope.row.Props.Name }}
        </el-text>
      </div>
      </template>
    </el-table-column>
    <el-table-column prop="RenderingChunks" label="Rendering" width="100" sortable/>
    <el-table-column prop="QueuedChunks" label="Queued" width="100"  />
    <el-table-column prop="PendingChunks" label="Pending" width="100"  />
    <el-table-column prop="Plug" label="Plugin" width="90"  />
    <el-table-column prop="Props.User" label="User" width="180"  />
    <el-table-column prop="Props.Pool" label="Pool" width="180"  />
    <el-table-column prop="Props.Pri" label="Priority" width="180"  />
    <el-table-column prop="Props.Grp" label="Group" width="180"  />
    <el-table-column prop="Props.Limits" label="Limits" width="180"  />
    <el-table-column label="Submitted" min-width="200" sortable>
      <template #default="scope">        
        <el-text class="mx-1" size="large">
          {{ submittedDate(scope.row) }}
        </el-text>
      </template>
    </el-table-column>    
  </el-table>
  
</template>

<script>

import { formatRelative } from 'date-fns'

export default {
  name: 'FarmJobs',
  data() {
        return {
        }
    },
    props: {
        jobs: Object
    },
    computed: {
    },
    methods: {
      submittedDate(job) {
        let submit_date = new Date(job.Date)
        let now = new Date()
        let date_wording = formatRelative(submit_date, now)
        return date_wording
        
      },
      createBackgroundString(job) {
        // Accumulate percentage of each status
        // ie. Complete 10% + rendering 50% + pending 30% + suspended = 5% + failed 5%
        var CompletePercent = job.progress_complete
        var RenderingPercent = job.progress_rendering
        var PendingPercent = job.progress_pending
        var FailedPercent = job.progress_failed
        var SuspendedPercent = job.progress_suspended
        
        // Ugly
        var C_R_Percent = (parseFloat(CompletePercent) + parseFloat(RenderingPercent))
        var C_R_P_Percent = C_R_Percent + parseFloat(PendingPercent)
        var C_R_P_F_Percent = C_R_P_Percent + parseFloat(FailedPercent)
        var C_R_P_F_S_Percent = C_R_P_F_Percent + parseFloat(SuspendedPercent)

        // Create gradient for CSS
        var gradient_css = "-webkit-linear-gradient(left,\
            rgba(42, 107, 255, 0.73) 0%,\
            rgba(42, 107, 255, 0.73) "+ CompletePercent +"%,\
            rgba(0,255,0,0.4) "+ CompletePercent +"%,\
            rgba(0,255,0,0.4) "+ C_R_Percent +"%,\
            rgba(255, 165, 0, 0.50) "+ C_R_Percent +"%,\
            rgba(255, 165, 0, 0.50) "+ C_R_P_Percent +"%,\
            rgba(255,0,0,0.6) "+ C_R_P_Percent +"%,\
            rgba(255,0,0,0.6) "+ C_R_P_F_Percent +"%,\
            rgba(100,100,100,0.9) "+ C_R_P_F_Percent +"%,\
            rgba(100,100,100,0.9) "+ C_R_P_F_S_Percent +"%,\
            rgba(0,0,0, 0.2) 0%)";

      return gradient_css
    },
  }
}
</script>


<style>


</style>